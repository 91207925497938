export const ROLES = {
    PARTICIPANT: 'participant',
    RESEARCHER: 'researcher',
};

export function isParticipant(role) {
    return role === ROLES.PARTICIPANT;
}

export function isResearcher(role) {
    return role === ROLES.RESEARCHER;
}
