/**
 * TODO: This method of checking cannot prevent importing and bundling.
 *  A better way based on env should be used.
 */

export function isWeb() {
    return typeof document !== 'undefined';
}

export function isReactNative() {
    return !isWeb() && typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
}
