export function isDev() {
    return process.env.NODE_ENV === 'development';
}

export function isProd() {
    return process.env.NODE_ENV === 'production';
}

export function isTest() {
    return process.env.NODE_ENV === 'test';
}

export function isLoadUserDataFromEnvEnabled() {
    return process.env.REACT_APP_USER_LOAD_FROM_ENV === 'true';
}

export function isLoadUserDataFromQueryParamsEnabled() {
    return process.env.REACT_APP_USER_LOAD_FROM_QUERY_PARAMS === 'true';
}

export function isLoadUserDataFromLocalStorageEnabled() {
    return process.env.REACT_APP_USER_LOAD_FROM_LOCAL_STORAGE === 'true';
}

export function showStudyHistory() {
    return process.env.REACT_APP_PDASH_SHOW_STUDY_HISTORY === 'true';
}

export function shouldLoadBaseUrlFromLocalStorage() {
    return process.env.REACT_APP_BASE_URL_LOAD_FROM_LOCAL_STORAGE === 'true';
}

// TODO: delete when participation_filter_box released
export function shouldShowRDashParticipationFilterBox() {
    return process.env.REACT_APP_RDASH_SHOW_PARTICIPATION_FILTER_BOX === 'true';
}

// TODO: delete when survey_responses_filter_box released
export function shouldShowRDashSurveyResponsesFilterBox() {
    return process.env.REACT_APP_RDASH_SHOW_SURVEY_RESPONSES_FILTER_BOX === 'true';
}

// TODO: delete when activity_sessions_calendar_view released
export function shouldShowRDashActivitySessionsCalendarView() {
    return process.env.REACT_APP_RDASH_SHOW_ACTIVITY_SESSIONS_CALENDAR_VIEW === 'true';
}

// TODO: delete when notifications_filter_box released
export function shouldShowRDashNotificationsFilterBox() {
    return process.env.REACT_APP_RDASH_SHOW_NOTIFICATIONS_FILTER_BOX === 'true';
}

export function isChatEnabled() {
    return process.env.REACT_APP_LOCAL_CHAT_ENABLED === 'true';
}

export function shouldShowRDashStudyStats() {
    return process.env.REACT_APP_RDASH_SHOW_STUDY_STATS === 'true';
}

export function isSensorDataVisualizationsEnabled() {
    return process.env.REACT_APP_SENSOR_DATA_VISUALIZATIONS_ENABLED === 'true';
}

export function shouldShowAuditTrailPage() {
    return process.env.REACT_APP_RDASH_SHOW_AUDIT_TRAIL === 'true';
}

export function isEdenEConsentEnabled() {
    return process.env.REACT_APP_RDASH_EDEN_E_CONSENT_ENABLED === 'true';
}
