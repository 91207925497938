import * as array from './core/array';
import * as component from './core/component';
import * as env from './core/env';
import * as hmr from './core/hmr';
import * as number from './core/number';
import * as object from './core/object';
import * as platform from './core/platform';
import * as string from './core/string';
import * as user from './core/user';

export default {
    env,
    hmr,
    component,
    platform,
    string,
    user,
    object,
    number,
    array,
};
