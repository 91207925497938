export function randomElement(array) {
    return array[Math.floor(array.length * Math.random())];
}

export function resizeFromEnd(array, targetLength, defaultValue) {
    if (array.length > targetLength) {
        return array.slice(0, targetLength);
    }
    if (array.length < targetLength) {
        return array.concat(new Array(targetLength - array.length).fill(defaultValue));
    }
    return [...array];
}
