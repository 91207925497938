import { round } from 'lodash-es';

export function calcPercentage(number, total, precision = 0) {
    return round((number * 100) / total, precision);
}

export function kgToLbs(value, precision = 17) {
    return round(value * 2.20462262185, precision);
}

export function lbsToKg(value, precision = 17) {
    return round(value * 0.45359237, precision);
}

export function cmToFt(value, precision = 17) {
    return round(value * 0.03280839895013123, precision);
}

export function ftToCm(value, precision = 17) {
    return round(value * 30.48, precision);
}

export function ftToFtAndIn(value, precision = 17) {
    let feet = value !== '' ? Math.trunc(value) : value;
    let inches = value !== '' ? round((value % 1) * 12, precision) : value;
    if (inches === 12) {
        feet += 1;
        inches = 0;
    } else if (inches === -12) {
        feet -= 1;
        inches = 0;
    }
    return { ft: feet, in: inches };
}
