export function capitalizeFirstChar(theString) {
    return theString.charAt(0).toUpperCase() + theString.slice(1);
}

export function getInOrdinalFormat(number) {
    return `${number}${['st', 'nd', 'rd'][((((number + 90) % 100) - 10) % 10) - 1] || 'th'}`;
}

export function stripHTMLDangerously(html) {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    let result = temp.textContent || temp.innerText || '';
    // \u200B => zero width space
    result.replace('\u200B', '');
    result = result.trim();
    return result;
}
